import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

const Shows = () => (
  <>
    <div className="contact-container" id="contact">
      <h1>shows</h1>
      <p className="contact-email">
        <a href="https://kungfunecktie.com/event/traitrs-saint-cyanide-gencab-new-desires-dj-set/kung-fu-necktie/philadelphia-pennsylvania/">Philadelphia: Thursday, November 7th</a>
      </p>
      <p>@ Kung Fu Necktie with Traitrs, genCAB and New Desires DJ Set</p>
    </div>
  </>
);

export default Shows;
